<template>
  <div class="login-body">
    <div class="login-container">
      <div class="login-form">
        <div class="login-form-logo">
          <img :src="$logoPath" />
        </div>

        <b-form @submit.prevent="onConfirm">
          <h5>Masuk</h5>

          <b-form-group label="Nama Pengguna">
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text><i class="fa fa-user"></i></b-input-group-text>
              </b-input-group-prepend>
              <b-form-input type="text" v-model="input.username" name="loginUsername" placeholder="Nama Pengguna"
                required></b-form-input>
            </b-input-group>
            <!-- v-on:keyup="handleInputOnKeyup" -->
            <!-- <small class="text-danger" v-if="!$v.input.username.required"
              >Nama pengguna tidak boleh kosong</small
            > -->
          </b-form-group>

          <b-form-group label="Kata Sandi">
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text><i class="fa fa-lock"></i></b-input-group-text>
              </b-input-group-prepend>
              <b-form-input v-model="input.password" name="loginPassword" placeholder="Kata Sandi"
                :type="!showPassword ? 'password' : 'text'" required></b-form-input>
              <!-- Attach Left button -->
              <b-input-group-append>
                <b-button variant="light" @click="toggleShow"><i class="fa" :class="{
                  'fa-eye-slash': !showPassword,
                  'fa-eye': showPassword,
                }"></i></b-button>
              </b-input-group-append>
            </b-input-group>
            <!-- <small class="text-danger" v-if="!$v.input.password.required"
              >Kata sandi tidak boleh kosong</small
            > -->
            <small class="text-danger" v-if="input.password && !$v.input.password.minLength">Kata sandi minimal 6
              karakter</small>
          </b-form-group>
          <b-button type="submit" name="loginBtn" class="pgi-btn" :disabled="this.$store.state.isLoggingIn">
            <span v-show="!this.$store.state.isLoggingIn">Masuk</span>

            <span v-show="this.$store.state.isLoggingIn">
              <span class="spinner-border spinner-border-sm"></span> Masuk
            </span>
          </b-button>
          <div class="login-error-wrapper mt-3">
            <div class="text-danger">
              {{
                this.$store.state.messageAuth
                ? "Nama pengguna atau kata sandi anda salah!"
                : ""
              }}
            </div>
          </div>
        </b-form>
        <p class="login-copyright">&copy;2023 {{ $footerName }}.</p>
      </div>
      <div class="login-content" v-bind:style="{ 'background-image': 'url(' + $bgLoginPath + ')' }">
        <div class="login-content-wrapper">
          <div class="login-sm-hidecontent">
            <h1>Selamat Datang!</h1>
            <p>Sistem Baru, Semangat Baru</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Change Password -->
    <!-- <b-modal
      id="modal-change-password"
      title="Ubah Password"
      no-close-on-esc
      no-close-on-backdrop
      :hide-header-close="isSubmitting"
    >
      <b-form id="changePasswordForm" @submit.prevent="doChangePassword">
        <div class="modal-form-wrapper">
          <div class="mb-4">
            Berdasarkan kebijakan perusahaan, anda diwajibkan mengubah password
            akun anda sebelum mengakses sistem.
          </div>
          <b-form-group label="Password Lama" class="password-form-group">
            <b-form-input
              v-model="input.oldPassword"
              name="oldPassword"
              :type="oldPasswordType"
              required
            ></b-form-input>
            <i :class="oldIconName" @click="changePasswordType(2)"></i>
          </b-form-group>
          <b-form-group label="Password Baru" class="password-form-group">
            <b-form-input
              v-model="input.newPassword"
              name="newPassword"
              :type="newPasswordType"
              minlength="6"
              required
            ></b-form-input>
            <i :class="newIconName" @click="changePasswordType(3)"></i>
          </b-form-group>
          <b-form-group
            label="Ketik Ulang Password Baru"
            class="password-form-group"
          >
            <b-form-input
              v-model="input.confirmPassword"
              name="confirmPassword"
              :type="confirmPasswordType"
              minlength="6"
              required
            ></b-form-input>
            <i :class="confirmIconName" @click="changePasswordType(4)"></i>
          </b-form-group>
        </div>
      </b-form>
      <b-alert show variant="warning" class="mb-0">
        * Tidak diperbolehkan menggunakan password lama
      </b-alert>
      <template v-slot:modal-footer>
        <b-button
          variant="pgiBtn"
          name="changePasswordBtn"
          type="submit"
          form="changePasswordForm"
          :disabled="isSubmitting"
        >
          {{ isSubmitting ? "Mengubah..." : "Ubah Password" }}
        </b-button>
      </template>
    </b-modal> -->
  </div>
</template>

<script>
import { validationMixin } from "/node_modules/vuelidate";
import { required, minLength } from "/node_modules/vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  name: "login",
  data() {
    return {
      input: {
        username: "",
        password: "",
        userId: null,
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      isSubmitting: false,
      isLoggingIn: false,
      errorMessage: "",
      showPassword: false,
    };
  },
  validations: {
    input: {
      username: { required },
      password: { required, minLength: minLength(6) },
    },
  },

  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    onConfirm: function () {
      this.$v.input.$touch();

      if (this.$v.input.$invalid) {
        return;
      } else {
        this.doLogin();
      }
    },
    doLogin() {
      this.$store
        .dispatch("retrieveAuthentication", {
          username: this.input.username,
          password: this.input.password,
        })
        .then((response) => {
          if (response.status == 200) {
            let role = response.data.user.role.id;
            let newUser = response.data.user.is_new_user;
            if (role == 100 && newUser == 0) {
              this.$router
                .push({ name: "Ubah Kata Sandi Maintenance" })
                .then(() => {
                  this.$bvModal.show("m-update-password-maintenance");
                });
            } else if (role == 100 && newUser == 1) {
              this.$router.push({ name: "Daftar FPP Maintenance" });
            } else {
              this.$router.push({ name: "Dashboard" });
            }
          }
        });

      // let payload = {
      //   email: this.input.username,
      //   password: this.input.password,
      // };
      // let currentTime = new Date().getTime();

      // this.isLoggingIn = true;
      // this.errorMessage = "";
      // this.$axios
      //   .post(`api/auth/login`, payload)
      //   .then((response) => {
      //     let data = response.data;
      //     let roleMenu = data.user.role.role_menu;

      //     localStorage.setItem("id", data.user.id);
      //     localStorage.setItem("name", data.user.name);
      //     localStorage.setItem("token", "Bearer " + data.token);
      //     localStorage.setItem("fcmToken", data.token);
      //     localStorage.setItem("role", data.user.role.name);
      //     localStorage.setItem("exp", currentTime + 3600000);
      //     localStorage.setItem("cabang", JSON.stringify(data.cabang));
      //     localStorage.setItem("isauthorized", "false");
      //     localStorage.setItem("menuTop", data.top_menu);

      //     var menuChild = [];
      //     for (var menu of roleMenu) {
      //       if (menu.menu != null) {
      //         menuChild.push(menu.menu.code);
      //       }
      //     }

      //     localStorage.setItem("menuChildren", menuChild);

      // localStorage.setItem("lapeksPassword", null)
      // to do flex baru cabang pusat
      // if (localStorage.getItem("version") === null) {
      //   localStorage.setItem("reload", 1)
      //   localStorage.setItem("version", data.version)
      // } else {
      //   if (localStorage.getItem("version") !== data.version) {
      //     localStorage.setItem("reload", 1)
      //   }

      //   localStorage.setItem("version", data.version)
      // }

      // // Setup activeCabang for display
      // let activeCabang = "Tanpa Cabang"
      // let activeCabangId = 0

      // if (data.cabang.length == 1) {
      //   activeCabang = data.cabang[0].name
      //   activeCabangId = data.cabang[0].id
      // }
      // else if (data.cabang.length > 1) {
      //   activeCabang = "Multi Cabang"
      //   activeCabangId = data.cabang[0].id
      // }

      // localStorage.setItem("activeCabang", activeCabang)
      // localStorage.setItem("activeCabangId", activeCabangId)

      // this.$router.push("/dashboard");

      // TODO OPEN AUTHORIZATION
      //   this.$axios.defaults.headers.common["Authorization"] =
      //     localStorage.getItem("token");
      // })
      // .catch((error) => {
      //   console.log(error);
      //   let reason = error.response.data.reason;

      //   if (reason == "CHANGE-PASSWORD-REQUIRED") {
      //     this.input.userId = error.response.data.data.id;
      //     this.$bvModal.show("modal-change-password");
      //   } else if (reason == "ERROR-LOCKED") {
      //     this.errorMessage =
      //       "User ini telah dilock. Mohon hubungi HO untuk keterangan lebih lanjut.";
      //   } else if (reason == "ERROR-NOT-FOUND") {
      //     this.errorMessage = "Username atau password anda salah!";
      //   } else if (reason == "END-OF-DAY") {
      //     this.errorMessage =
      //       "Anda sudah tidak dapat masuk pada saat ini (22:00 - 06:00)";
      //   } else {
      //     this.$helper.toastErr(this, reason);
      //   }
      // })
      // .finally(() => {
      //   this.isLoggingIn = false;
      // });
    },

    // doChangePassword() {
    //   let payload = new FormData();
    //   payload.append("userId", this.input.userId);
    //   payload.append("oldPassword", this.input.oldPassword);
    //   payload.append("newPassword", this.input.newPassword);
    //   payload.append("repeatNewPassword", this.input.confirmPassword);

    //   this.isSubmitting = true;
    //   this.$axios
    //     .post("user/changePassword", payload)
    //     .then(() => {
    //       this.input.password = "";
    //       this.$bvModal.hide("modal-change-password");
    //       this.$helper.toastSucc(
    //         this,
    //         "Password berhasil diubah, silahkan login kembali!"
    //       );
    //     })
    //     .catch((error) => {
    //       this.$helper.parseError(this, error);
    //     })
    //     .finally(() => {
    //       this.isSubmitting = false;
    //     });
    // },

    // handleInputOnKeyup() {
    //   this.input.username = this.input.username.toLowerCase();
    // },
  },
};
</script>